import { captureException } from '@sentry/react';
import type { GraphQLFormattedError } from 'graphql';
import { forEach, isBoolean, isEqual, isNil, omit } from 'lodash';
import type { IntlShape } from 'react-intl';
import reduxStore from 'store';
import { v4 as uuid } from 'uuid';
import type { CalendarDate } from '@internationalized/date';
import { parseAbsoluteToLocal, toCalendarDate } from '@internationalized/date';

import { parseContext } from '../../hooks/useEmissionsLegs';
import type {
  ComputePublicFreightEmissionMutation,
  GetPublicCalculatorResultQuery,
} from '../../services/graphql-public/generated';
import type {
  ComputeFreightEmissionMutation,
  GetCalculatorResultQuery,
  GetEmissionQuery,
} from '../../services/graphql/generated';
import {
  CalculatorFreightFuelTypeEnum,
  EmissionFreightCargoTypeEnum,
  EmissionFreightDistanceUnitEnum,
  EmissionFreightFuelConsumptionUnitEnum,
  EmissionFreightWeightUnitEnum,
  EmissionModeEnum,
} from '../../services/graphql/generated';
import type { Invert } from '../../utils/types';
import type { ClarityContext } from '../EmissionTabs/EmissionContext/ClarityDetail/types';

import {
  EMISSION_FREIGHT_VEHICLE_CODES,
  GLEC_V3_FRAMEWORK_INCEPTION_DATE,
  NORTH_AMERICAN_VEHICLE_CODES,
  OUTSIDE_NORTH_AMERICA_VEHICLE_CODES,
  PLEDGE_SIGNUP_URL,
} from './constants/constants';
import { mapCalculatorStateToPublicAPIRequest } from './utils/mapCalculatorStateToPublicAPIRequest';
import type {
  CalculationError,
  CalculationErrorType,
  CalculationInputCargoDetail,
  CalculationResultParent,
  CalculatorInputLeg,
  CalculatorInputLegV2,
  CalculatorLocation,
  CalculatorParentState,
  EmissionFreightModeEnum,
} from './types';
import { CalculatorLocationTypeEnum, EmissionFreightVehicleCodeEnum, LogisticHubsTypeEnum } from './types';

export const SHIPMENT_VERSION_METADATA_KEY = 'shipment_id';

export const mapVehicleCodeToMode = (vehicleCode: EmissionFreightVehicleCodeEnum): EmissionFreightModeEnum => {
  let mode: EmissionModeEnum | null = null;
  Object.entries(EMISSION_FREIGHT_VEHICLE_CODES).forEach(([key, value]) => {
    if (value.includes(vehicleCode)) {
      mode = key as EmissionModeEnum;
    }
  });

  if (!mode) throw new Error(`Mode could not be found for given vehicle code, ${vehicleCode}`);

  return mode;
};

export const mapModeToDefaultVehicleCode = (
  mode: EmissionFreightModeEnum,
  config?: {
    isUsingContainers?: boolean;
    isNaSmartway?: boolean;
  },
): EmissionFreightVehicleCodeEnum => {
  const vehicleCodes = EMISSION_FREIGHT_VEHICLE_CODES[mode];
  if (!vehicleCodes) throw new Error('Vehicle codes could not be found for given mode');

  switch (mode) {
    case EmissionModeEnum.Air:
      return EmissionFreightVehicleCodeEnum.P23A;
    case EmissionModeEnum.InlandWaterway:
      return EmissionFreightVehicleCodeEnum.P23Bmv;
    case EmissionModeEnum.Sea:
      return config?.isUsingContainers ? EmissionFreightVehicleCodeEnum.P23Scs : EmissionFreightVehicleCodeEnum.P23Sgc;
    case EmissionModeEnum.Rail:
      return EmissionFreightVehicleCodeEnum.P23T;
    case EmissionModeEnum.Road:
      return config?.isNaSmartway ? EmissionFreightVehicleCodeEnum.P23NaHg : EmissionFreightVehicleCodeEnum.P23H;
    default:
      // @ts-expect-error -- enabling strict mode
      return vehicleCodes[0];
  }
};

export function getNextSerialObjectId(items: { id: number }[]) {
  if (items.length === 0) return 0;

  const ids = items.map((item) => item.id);
  return Math.max(...ids) + 1;
}

export function isLogisticsHub(vehicle: EmissionFreightVehicleCodeEnum): vehicle is LogisticHubsTypeEnum {
  return Object.values(LogisticHubsTypeEnum).some((logisticsHubType) => logisticsHubType === vehicle);
}

/** @internal used in unit test */
export const LOGISTICS_HUB_TYPE_TO_VEHICLE_CODE = {
  [LogisticHubsTypeEnum.LSC]: EmissionFreightVehicleCodeEnum.Lsc,
  [LogisticHubsTypeEnum.LST]: EmissionFreightVehicleCodeEnum.Lst,
  [LogisticHubsTypeEnum.LSST]: EmissionFreightVehicleCodeEnum.Lsst,
  [LogisticHubsTypeEnum.LSW]: EmissionFreightVehicleCodeEnum.Lsw,
  [LogisticHubsTypeEnum['P23:LSC']]: EmissionFreightVehicleCodeEnum.P23Lsc,
  [LogisticHubsTypeEnum['P23:LST']]: EmissionFreightVehicleCodeEnum.P23Lst,
  [LogisticHubsTypeEnum['P23:LSST']]: EmissionFreightVehicleCodeEnum.P23Lsst,
  [LogisticHubsTypeEnum['P23:LSW']]: EmissionFreightVehicleCodeEnum.P23Lsw,
  [LogisticHubsTypeEnum['P23:LSLB']]: EmissionFreightVehicleCodeEnum.P23Lslb,
} as const satisfies Record<LogisticHubsTypeEnum, EmissionFreightVehicleCodeEnum>;

/** @internal used in unit test */
export const LOGISTICS_HUB_VEHICLE_CODE_TO_TYPE: Invert<typeof LOGISTICS_HUB_TYPE_TO_VEHICLE_CODE> = {
  [EmissionFreightVehicleCodeEnum.Lsc]: LogisticHubsTypeEnum.LSC,
  [EmissionFreightVehicleCodeEnum.Lst]: LogisticHubsTypeEnum.LST,
  [EmissionFreightVehicleCodeEnum.Lsst]: LogisticHubsTypeEnum.LSST,
  [EmissionFreightVehicleCodeEnum.Lsw]: LogisticHubsTypeEnum.LSW,
  [EmissionFreightVehicleCodeEnum.P23Lsc]: LogisticHubsTypeEnum['P23:LSC'],
  [EmissionFreightVehicleCodeEnum.P23Lst]: LogisticHubsTypeEnum['P23:LST'],
  [EmissionFreightVehicleCodeEnum.P23Lsst]: LogisticHubsTypeEnum['P23:LSST'],
  [EmissionFreightVehicleCodeEnum.P23Lsw]: LogisticHubsTypeEnum['P23:LSW'],
  [EmissionFreightVehicleCodeEnum.P23Lslb]: LogisticHubsTypeEnum['P23:LSLB'],
};

// The new schema does not support logistic hubs, so we need to insert new legs when the from/to detail type is a logistic hub.
export function mapCalculatorLegsToV2Legs(legs: CalculatorInputLeg[]): CalculatorInputLegV2[] {
  return legs.reduce<CalculatorInputLegV2[]>((acc, leg) => {
    const locationDetails = {
      from: leg.from_detail,
      to: leg.to_detail,
    };

    const v2Leg = omit(leg, ['from_detail', 'to_detail']);

    const legsToAdd = [v2Leg] as CalculatorInputLegV2[];

    // We need to consolidate the logistics hub from the previous leg 'to_detail' and the current leg 'from_detail'.
    // So if the previous leg is a logistics hub, we will not add an identical leg again.
    const previousLeg = acc[acc.length - 1];
    const previousLegIsLogisticsHub =
      previousLeg?.detail?.vehicle_used && isLogisticsHub(previousLeg.detail.vehicle_used);

    if (locationDetails.from?.type && !previousLegIsLogisticsHub && !locationDetails.from.is_auto_transshipment) {
      legsToAdd.unshift({
        id: getNextSerialObjectId([...legs, ...acc, ...legsToAdd]),
        detail: {
          vehicle_used: leg.from_detail?.type ? LOGISTICS_HUB_TYPE_TO_VEHICLE_CODE[leg.from_detail.type] : undefined,
          refrigerated: locationDetails.from.is_refrigerated,
        },
        to: leg.from,
        from: leg.from,
        isSaved: true,
      });
    }

    if (locationDetails.to?.type && !locationDetails.to.is_auto_transshipment) {
      legsToAdd.push({
        id: getNextSerialObjectId([...legs, ...acc, ...legsToAdd]),
        detail: {
          vehicle_used: leg.to_detail?.type ? LOGISTICS_HUB_TYPE_TO_VEHICLE_CODE[leg.to_detail.type] : undefined,
          refrigerated: locationDetails.to.is_refrigerated,
        },
        from: leg.to,
        to: leg.to,
        isSaved: true,
      });
    }

    return [...acc, ...legsToAdd];
  }, []);
}

/**
 * Returns true if the location is in the North American SmartWay region
 * @see https://www.epa.gov/smartway/north-american-smartway
 */
export function locationIsInNaSmartway(location: Partial<CalculatorLocation>) {
  return !location?.country_code_alpha_2 ? false : ['US', 'CA'].includes(location.country_code_alpha_2.toUpperCase());
}

const MILES_TO_KM_FACTOR = 1.60934;

export function convertDistanceToKM(distance: number, unit: EmissionFreightDistanceUnitEnum) {
  switch (unit) {
    case EmissionFreightDistanceUnitEnum.Km:
      return distance;
    case EmissionFreightDistanceUnitEnum.Mi:
      return distance * MILES_TO_KM_FACTOR;
    default:
      throw new Error('Invalid distance unit');
  }
}

export function predictVehicleCode(cargoDetail: CalculationInputCargoDetail, leg?: CalculatorInputLegV2) {
  const useLegacyCodes = cargoDetail.date && cargoDetail.date.compare(GLEC_V3_FRAMEWORK_INCEPTION_DATE) < 0;
  let predictedCode: EmissionFreightVehicleCodeEnum = useLegacyCodes
    ? EmissionFreightVehicleCodeEnum.H
    : EmissionFreightVehicleCodeEnum.P23H;

  // Predict the most likely starting scenario/mode

  // If is location leg
  if (leg?.from && leg.to) {
    // "from" location takes precedence over "to" location
    forEach([leg.to, leg.from], (location) => {
      if (location.type === CalculatorLocationTypeEnum.AIRPORT) {
        predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.A : EmissionFreightVehicleCodeEnum.P23A;
      }

      if (location.type === CalculatorLocationTypeEnum.PORT) {
        predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.Sgc : EmissionFreightVehicleCodeEnum.P23Sgc;
      }

      const isNaSmartway = locationIsInNaSmartway(location);
      if (location.type === CalculatorLocationTypeEnum.CITY && isNaSmartway) {
        predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.NaHg : EmissionFreightVehicleCodeEnum.P23NaHg;
      }
      if (location.type === CalculatorLocationTypeEnum.CITY && !isNaSmartway) {
        predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.H : EmissionFreightVehicleCodeEnum.P23H;
      }

      if (location.display_name?.toLowerCase().includes('airport')) {
        predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.A : EmissionFreightVehicleCodeEnum.P23A;
      }

      if (location.display_name?.toLowerCase().startsWith('port')) {
        predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.Sgc : EmissionFreightVehicleCodeEnum.P23Sgc;
      }
    });
  }

  if (
    mapVehicleCodeToMode(predictedCode) === EmissionModeEnum.Sea &&
    cargoDetail.weight_unit === EmissionFreightWeightUnitEnum.Teu
  ) {
    predictedCode = useLegacyCodes ? EmissionFreightVehicleCodeEnum.Scs : EmissionFreightVehicleCodeEnum.P23Scs;
  }

  return predictedCode;
}

/**
 * Returns a new array of legs with the from locations updated to match the deleted leg
 */
export function consolidateDeletedLegLocations(legs: CalculatorInputLeg[], deletedLegIndex: number) {
  return legs.map((leg, idx) => {
    const updatedLeg = { ...leg };

    if (idx - 1 === deletedLegIndex) {
      // @ts-expect-error -- enabling strict mode
      updatedLeg.from = legs[idx - 1].from;
    }

    return updatedLeg;
  });
}

export function consolidateLegLocations(
  legs: CalculatorInputLeg[],
  updatedLeg: CalculatorInputLeg,
): CalculatorInputLeg[] {
  const newLegs = [...legs];

  const updatedLegIndex = legs.findIndex((leg) => leg.id === updatedLeg.id);

  const nextLeg = newLegs[updatedLegIndex + 1] as CalculatorInputLeg | undefined;
  const prevLeg = newLegs[updatedLegIndex - 1] as CalculatorInputLeg | undefined;

  // Update the next leg's origin if current destination is not the same
  if (updatedLeg.to && nextLeg?.from && updatedLeg.to.id !== nextLeg.from.id) {
    nextLeg.from = updatedLeg.to;

    // Also update the next leg's destination if it's a logistics hub
    if (nextLeg.detail?.vehicle_used && isLogisticsHub(nextLeg.detail.vehicle_used)) {
      nextLeg.to = updatedLeg.to;

      // Also update the following leg's origin if next leg is a logistics hub
      const followingLeg = newLegs[updatedLegIndex + 2];
      if (followingLeg) {
        // @ts-expect-error -- enabling strict mode
        newLegs[updatedLegIndex + 2].from = updatedLeg.to;
        newLegs[updatedLegIndex + 2] = { ...followingLeg };
      }
    }

    newLegs[updatedLegIndex + 1] = { ...nextLeg };
  }

  // Update the previous leg's destination if current origin is not the same
  if (updatedLeg.from && prevLeg?.to && updatedLeg.from.id !== prevLeg.to.id) {
    prevLeg.to = updatedLeg.from;
    newLegs[updatedLegIndex - 1] = { ...prevLeg };
  }

  return newLegs;
}

function consolidateEmissionCalculationAssumptions(emissionCalculation: ClarityContext['emissions_calculation']) {
  return {
    ...emissionCalculation?.assumptions,
    ...emissionCalculation?.factors?.[0]?.assumptions,
    ...emissionCalculation?.methodology?.assumptions,
  };
}

/**
 *
 * @param emission
 * @returns the parent state of the calculator from a given saved emission
 * @throws {Error} if the emission is missing required fields
 */
export function getCalculatorParentStateFromEmission(emission: GetEmissionQuery['emission']): CalculatorParentState {
  if (!emission.weight_kg) {
    throw new Error('Missing weight_kg');
  }

  const cargoDetail: CalculationInputCargoDetail = {
    weight: emission.weight_kg,
    weight_unit: EmissionFreightWeightUnitEnum.Kg,
    cargo_type: EmissionFreightCargoTypeEnum.Average,
    date: emission.date ? toCalendarDate(parseAbsoluteToLocal(emission.date)) : undefined,
  };

  const legs = emission.emission_children.map((child, index): CalculatorInputLegV2 => {
    const activity = child?.freight;
    if (!child.context_stringify) {
      throw new Error('Missing context_stringify');
    }

    if (!activity) {
      throw new Error('Missing freight');
    }

    if (!activity.vehicle_code) {
      throw new Error('Missing vehicle_code');
    }

    // * We need to use the context to get the original values here as most database values are derived during the calculation.
    const segmentContexts = child.emission_children?.reduce((acc, segment) => {
      if (segment.context_stringify) {
        acc.push(JSON.parse(segment.context_stringify));
      }
      return acc;
    }, [] as ClarityContext[]);

    const emissionAssumptions = segmentContexts.map((c) =>
      consolidateEmissionCalculationAssumptions(c.emissions_calculation),
    );
    const distance = emissionAssumptions.reduce((acc, assumption) => {
      // @ts-expect-error -- enabling strict mode
      if (assumption.distance) {
        // @ts-expect-error -- enabling strict mode
        return acc + (assumption?.distance?.input_value ?? 0);
      }
      return acc;
    }, 0);

    // @ts-expect-error -- enabling strict mode
    const vehicleCode = emissionAssumptions?.[0]?.vehicle_code?.input_value ?? activity.vehicle_code;

    if (!vehicleCode) {
      throw new Error('Missing vehicle_code');
    }

    const calculatorLeg: CalculatorInputLeg = {
      id: index,
      distance,
      distance_unit: EmissionFreightDistanceUnitEnum.Km,
      isSaved: true,
    };

    if (child.freight?.route_details?.stops) {
      calculatorLeg.stops = child.freight.route_details.stops
        .filter((stop) => !!stop?.code)
        .map(
          (stop): CalculatorLocation => ({
            id: uuid(),
            type: stop.code?.length === 3 ? CalculatorLocationTypeEnum.AIRPORT : CalculatorLocationTypeEnum.PORT,
            iata_code: stop.code?.length === 3 ? stop.code : undefined,
            unlocode: stop.code?.length === 5 ? stop.code : undefined,
            name: stop?.stop_info?.name ?? stop.code!,
            display_name: stop?.stop_info?.name ?? stop.code!,
            country_code_alpha_2: stop.stop_info?.code_alpha_2 ?? undefined,
          }),
        );
    }

    if (!distance) {
      delete calculatorLeg.distance;
      const fromCoordinates = activity.from_coordinates?.coordinates?.slice(0).reverse();

      if (!activity.from_address && !activity.from_display && !fromCoordinates) {
        throw new Error('No origin location to display');
      }

      const fromDisplayName = activity.from_address ?? activity.from_display ?? `(${fromCoordinates?.join(', ')})`;

      calculatorLeg.from = {
        display_name: fromDisplayName,
        name: activity.from_display,
        id: child.id,
        type: CalculatorLocationTypeEnum.CITY,
      };

      if (fromCoordinates) {
        // @ts-expect-error -- enabling strict mode
        calculatorLeg.from.coordinates = [fromCoordinates[0], fromCoordinates[1]];
      }

      const toCoordinates = activity.to_coordinates?.coordinates?.slice(0).reverse();

      if (!activity.to_address && !activity.to_display && !toCoordinates) {
        throw new Error('No destination location to display');
      }

      const toDisplayName = activity.to_address ?? activity.to_display ?? `(${toCoordinates?.join(', ')})`;

      calculatorLeg.to = {
        display_name: toDisplayName,
        name: activity.to_display,
        id: child.id,
        type: CalculatorLocationTypeEnum.CITY,
      };

      if (toCoordinates) {
        // @ts-expect-error -- enabling strict mode
        calculatorLeg.to.coordinates = [toCoordinates[0], toCoordinates[1]];
      }
    }

    calculatorLeg.detail = {
      vehicle_used: vehicleCode as EmissionFreightVehicleCodeEnum,
      emission_standard: activity.emission_standard ?? undefined,
      empty_running: activity.empty_running ?? undefined,
      flight_no: activity.flight_no ?? undefined,
      load_factor: activity.load_factor ?? undefined,
      load_type: activity.load_type ?? undefined,
      refrigerated: activity.is_refrigerated ?? undefined,
      fuel_type: (activity.fuel_type ?? undefined) as CalculatorFreightFuelTypeEnum | undefined,
      fuel_consumption: activity.fuel_consumption ?? undefined,
      fuel_consumption_unit: (activity.fuel_consumption_unit ?? undefined) as
        | EmissionFreightFuelConsumptionUnitEnum
        | undefined,
      aircraft_model: {
        aircraft_type: activity.aircraft_code ?? undefined,
        iata_code: activity.aircraft_code,
      },
      carrier: {
        name: activity.carrier_name ?? undefined,
        code: activity.carrier_code ?? undefined,
      },
      vessel: {
        id: activity.vessel_id ?? undefined,
        name: activity.vessel_name ?? undefined,
      },
    };

    return calculatorLeg;
  });

  return {
    legs,
    cargoDetail,
  };
}

/**
 * Evaluates if the given state is valid to be sent to public API
 */
export function validCalculatorParentState(state: CalculatorParentState): boolean {
  if (!state.cargoDetail.weight) {
    return false;
  }

  // Must either be all distance legs or all location legs
  if (
    !(
      state.legs.every((leg) => !isNil(leg.distance) && !leg.from && !leg.to) ||
      state.legs.every((leg) => leg.from && leg.to && isNil(leg.distance))
    )
  ) {
    return false;
  }

  return state.legs.every((leg, index, legs) => {
    if (isNil(leg.id)) {
      return false;
    }

    if (isNil(leg.distance) && !(leg.from && leg.to)) {
      return false;
    }

    const isLocationLeg = leg.from && leg.to;

    // if is location leg, check all locations are aligned with prev/next leg
    if (isLocationLeg) {
      if ((leg.from && !leg.from.name && !leg.from.display_name) || (leg.to && !leg.to.name && !leg.to.display_name)) {
        return false;
      }

      const prevLeg = legs[index - 1];
      const nextLeg = legs[index + 1];

      if (prevLeg && prevLeg.to?.name !== leg.from?.name) {
        return false;
      }

      if (nextLeg && nextLeg.from?.name !== leg.to?.name) {
        return false;
      }
    }

    if (!leg.detail?.vehicle_used) {
      return false;
    }

    return true;
  });
}

const PUBLIC_USER_ID_STORAGE_KEY = 'public_client_id';

export const getPublicUserId = (): string => {
  let store = {
    getItem: (key: string) => localStorage.getItem(key),
    setItem: (key: string, value: string) => localStorage.setItem(key, value),
    removeItem: (key: string) => localStorage.removeItem(key),
  };

  let isLocalStorageBlocked = false;
  // check if local storage is blocked - unfortunately there is no standardised api to check this
  try {
    store.setItem('check', 'check');
    store.removeItem('check');
  } catch (error) {
    isLocalStorageBlocked = true;
  }

  // check if redux store is available
  if (!reduxStore?.enabled) {
    return uuid();
  }

  // if cannot use local storage, fallback to redux
  if (isLocalStorageBlocked) {
    store = {
      getItem: (key: string) => reduxStore.get(key),
      setItem: (key: string, value: string) => reduxStore.set(key, value),
      removeItem: (key: string) => reduxStore.remove(key),
    };
  }

  const id = store.getItem(PUBLIC_USER_ID_STORAGE_KEY);

  if (id) {
    return id;
  }

  // generate a new id
  const newId = uuid();
  store.setItem(PUBLIC_USER_ID_STORAGE_KEY, newId);
  return newId;
};

export function getEmbedKey() {
  return new URLSearchParams(window.location.search).get('clcEmbedKey');
}

export function isParentRequestEqual(x: CalculatorParentState | null, y: CalculatorParentState | null) {
  let a: unknown = x;
  let b: unknown = y;

  try {
    if (x) {
      a = mapCalculatorStateToPublicAPIRequest(x);
    }
    if (y) {
      b = mapCalculatorStateToPublicAPIRequest(y);
    }
  } catch (error) {
    captureException(error, {
      extra: {
        newState: JSON.stringify(x),
        oldState: JSON.stringify(y),
      },
    });
    return false;
  }

  return isEqual(a, b);
}

const userInputErrorTypes = new Set<CalculationErrorType>([
  'route_calculation_error',
  'p23_vehicle_mapping_error',
  'truck_max_payload_exceeded_error',
  'metadata_key_required_error',
  'version_metadata_used_by_unversioned_emission',
]);

export function isUserInputError(error: GraphQLFormattedError): boolean {
  // @ts-expect-error -- enabling strict mode
  const type = error.extensions?.type as CalculationErrorType | undefined;
  if (!type) {
    return false;
  }

  return userInputErrorTypes.has(type);
}

export function formatCalculationErrorMessage(intl: IntlShape, error: CalculationError): string | undefined {
  // In most cases the default error message is sufficient, but some errors need to be overridden to be more user friendly.
  switch (error.type) {
    case 'p23_vehicle_mapping_error':
      return intl.formatMessage({ id: 'emissions.calculator.results.error.p23_vehicle_mapping_error.message' });
    case 'truck_max_payload_exceeded_error':
      return intl.formatMessage({ id: 'emissions.calculator.results.error.truck_max_payload_exceeded_error.message' });
    case 'metadata_key_required_error':
      return intl.formatMessage({ id: 'emissions.calculator.results.error.metadata_key_required_error.message' });
    case 'version_metadata_used_by_unversioned_emission':
      return intl.formatMessage({
        id: 'emissions.calculator.results.error.version_metadata_used_by_unversioned_emission.message',
      });
    default:
      return error.message;
  }
}

export const isValidSubmission = (calculatorParentState: CalculatorParentState): boolean => {
  if (!calculatorParentState.cargoDetail.weight) return false;
  return calculatorParentState.legs.every((leg) => {
    if (leg.distance) return true;
    if (leg.from && leg.to) return true;
    return false;
  });
};

function newLocationDetailFromLegV2(leg?: CalculatorInputLegV2): CalculatorInputLeg['from_detail' | 'to_detail'] {
  if (leg?.detail?.vehicle_used && isLogisticsHub(leg.detail?.vehicle_used)) {
    return {
      type: leg.detail.vehicle_used,
      is_refrigerated: leg?.detail.refrigerated,
      is_auto_transshipment: leg.is_auto_transshipment,
    };
  }

  return {
    type: null,
    is_refrigerated: undefined,
  };
}

export function mapV2LegsToInputLegs(legs: CalculatorInputLegV2[]): CalculatorInputLeg[] {
  const legsWithLocations: CalculatorInputLeg[] = [];

  return legs.reduce((acc, leg, index) => {
    if (leg?.detail?.vehicle_used && isLogisticsHub(leg.detail.vehicle_used)) {
      return acc;
    }

    const previousLeg = legs[index - 1];
    const nextLeg = legs[index + 1];

    acc.push({
      ...leg,
      from_detail: newLocationDetailFromLegV2(previousLeg),
      to_detail: newLocationDetailFromLegV2(nextLeg),
      is_auto_transshipment: false,
    });

    return acc;
  }, legsWithLocations);
}

export function removeAutoTransshipmentLegs(legs: CalculatorInputLeg[]): CalculatorInputLeg[] {
  return legs.map((leg) => ({
    ...leg,
    from_detail: leg.from_detail?.is_auto_transshipment ? { type: null } : leg.from_detail,
    to_detail: leg.to_detail?.is_auto_transshipment ? { type: null } : leg.to_detail,
  }));
}

export function updateAutoTransshipmentLegs(
  legs: CalculatorInputLeg[],
  results: CalculationResultParent,
): CalculatorInputLeg[] {
  /**
   * If MT = Manual Transshipment
   * AT = Auto Transshipment
   * M = Movement
   *
   * Results may look like:
   * [ MT, M, MT, M, LT, M, M ]
   * or similar,
   * where in 'legs' they have transshipment folded in to them in 'from/to_details'
   *
   * The output should be the existing legs, with auto-transshipment legs possibly added
   * to the from/to_details of the existing legs.
   */

  let resultsIndex = 0;
  return legs.map((leg) => {
    let result = results.emission_children[resultsIndex];
    let fromDetail = leg.from_detail;
    let toDetail = leg.to_detail;

    if (result?.mode === EmissionModeEnum.LogisticsSite) {
      resultsIndex += 1;
      result = results.emission_children[resultsIndex];
    }

    if (!leg.detail?.vehicle_used) {
      return leg;
    }

    const mode = mapVehicleCodeToMode(leg.detail.vehicle_used);
    const nextResultMatchingModeIndex = results.emission_children.slice(resultsIndex).findIndex((r) => r.mode === mode);

    if (nextResultMatchingModeIndex === -1) {
      const error = new Error(
        `Unable to find next calculation result leg matching mode "${mode}" when updating legs for auto-transshipment.`,
      );
      captureException(error, {
        extra: {
          legs,
          results,
        },
      });

      throw error;
    }
    resultsIndex += nextResultMatchingModeIndex;

    const previousResult = results.emission_children[resultsIndex - 1];
    if (
      previousResult?.mode === EmissionModeEnum.LogisticsSite &&
      (leg.from_detail?.type === null || leg.from_detail?.type === undefined || leg.to_detail?.is_auto_transshipment)
    ) {
      const parsedContext = parseContext(previousResult);
      const vehicleCodeInputValue =
        parsedContext?.assumptions?.vehicle_code?.input_value ?? EmissionFreightVehicleCodeEnum.Lst;
      const isRefrigeratedInputValue = parsedContext?.assumptions?.is_refrigerated?.input_value;

      fromDetail = {
        // @ts-expect-error -- enabling strict mode
        type: LOGISTICS_HUB_VEHICLE_CODE_TO_TYPE[vehicleCodeInputValue],
        is_refrigerated: isRefrigeratedInputValue,
        is_auto_transshipment: true,
      };
    }

    const nextResult = results.emission_children[resultsIndex + 1];
    if (
      nextResult?.mode === EmissionModeEnum.LogisticsSite &&
      (leg.to_detail?.type === null || leg.to_detail?.type === undefined || leg.to_detail.is_auto_transshipment)
    ) {
      const parsedContext = parseContext(nextResult);
      const vehicleCodeInputValue =
        parsedContext?.assumptions?.vehicle_code?.input_value ?? EmissionFreightVehicleCodeEnum.Lst;
      const isRefrigeratedInputValue = parsedContext?.assumptions?.is_refrigerated?.input_value;

      toDetail = {
        // @ts-expect-error -- enabling strict mode
        type: LOGISTICS_HUB_VEHICLE_CODE_TO_TYPE[vehicleCodeInputValue],
        is_refrigerated: isRefrigeratedInputValue,
        is_auto_transshipment: true,
      };
    }

    resultsIndex += 1;
    return {
      ...leg,
      from_detail: fromDetail,
      to_detail: toDetail,
    };
  });
}

interface UtmParams {
  source?: string;
  medium?: string;
  term?: string;
  content?: string;
  campaign?: string;
}
export function buildUtmSignUpUrl(params: UtmParams): URL {
  const url = new URL(PLEDGE_SIGNUP_URL);

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(`utm_${key}`, value);
    }
  });

  return url;
}

export function getIframeOrigin(window: Window, document: Document): string {
  const iframeAncestor = window?.location?.ancestorOrigins?.[0];
  return iframeAncestor || document?.referrer || window?.location?.origin;
}

export function getCalculatorInputFromQuery(query: GetCalculatorResultQuery | GetPublicCalculatorResultQuery) {
  return 'calculator_input' in query ? query.calculator_input : query.public_calculator_input;
}

export function getUntrackedEmissionFromQuery(query: GetCalculatorResultQuery | GetPublicCalculatorResultQuery) {
  return 'untracked_emission' in query ? query.untracked_emission : query.public_untracked_emission;
}

export function getUntrackedEmissionFromMutation(
  untrackedEmissionMutation: ComputeFreightEmissionMutation | ComputePublicFreightEmissionMutation,
) {
  return 'public_compute_freight_emission' in untrackedEmissionMutation
    ? untrackedEmissionMutation.public_compute_freight_emission
    : untrackedEmissionMutation.compute_freight_emission;
}

export function isFuelConsumptionUnitValidForFuelType(
  fuelType: CalculatorFreightFuelTypeEnum,
  unit: EmissionFreightFuelConsumptionUnitEnum,
): 'FUEL_CONSUMPTION_NOT_SUPPORTED_FOR_FUEL_TYPE' | 'FUEL_CONSUMPTION_UNIT_NOT_COMPATIBLE_WITH_FUEL_TYPE' | 'VALID' {
  const supportedFuelTypes: EmissionFreightFuelConsumptionUnitEnum[] = [];
  switch (fuelType) {
    case CalculatorFreightFuelTypeEnum.Diesel:
    case CalculatorFreightFuelTypeEnum.Petrol:
    case CalculatorFreightFuelTypeEnum.Lpg:
      supportedFuelTypes.push(
        EmissionFreightFuelConsumptionUnitEnum.ImpGalPer_100Mi,
        EmissionFreightFuelConsumptionUnitEnum.UsGalPer_100Mi,
        EmissionFreightFuelConsumptionUnitEnum.LPer_100Km,
      );
      break;
    case CalculatorFreightFuelTypeEnum.Cng:
    case CalculatorFreightFuelTypeEnum.Lng:
      supportedFuelTypes.push(
        EmissionFreightFuelConsumptionUnitEnum.KgPer_100Km,
        EmissionFreightFuelConsumptionUnitEnum.LbsPer_100Mi,
      );
      break;
    case CalculatorFreightFuelTypeEnum.Electricity:
      supportedFuelTypes.push(
        EmissionFreightFuelConsumptionUnitEnum.KwhPer_100Km,
        EmissionFreightFuelConsumptionUnitEnum.KwhPer_100Mi,
      );
      break;
    default:
      return 'FUEL_CONSUMPTION_NOT_SUPPORTED_FOR_FUEL_TYPE';
  }
  if (unit && !supportedFuelTypes.includes(unit)) {
    return 'FUEL_CONSUMPTION_UNIT_NOT_COMPATIBLE_WITH_FUEL_TYPE';
  }

  return 'VALID';
}

export const getVehicleCodesForMode = (
  mode: EmissionModeEnum,
  options?: {
    originIsInNA?: boolean;
    date?: CalendarDate;
  },
): EmissionFreightVehicleCodeEnum[] =>
  (Object.keys(EMISSION_FREIGHT_VEHICLE_CODES) as EmissionModeEnum[])
    .filter((key) => key === mode)
    .flatMap((key) =>
      // @ts-expect-error -- enabling strict mode
      EMISSION_FREIGHT_VEHICLE_CODES[key]
        .filter((code: EmissionFreightVehicleCodeEnum) => {
          if (!isBoolean(options?.originIsInNA)) return true;
          return options?.originIsInNA
            ? !OUTSIDE_NORTH_AMERICA_VEHICLE_CODES.includes(code as any)
            : !NORTH_AMERICAN_VEHICLE_CODES.includes(code as any);
        })
        .filter((code: EmissionFreightVehicleCodeEnum) => {
          if (options?.date && options.date.compare(GLEC_V3_FRAMEWORK_INCEPTION_DATE) < 0) {
            return !code.startsWith('P23:');
          }
          return code.startsWith('P23:');
        }),
    );
